import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import styles from "./job.module.css"

export default ({ data }) => {
  const job = data.markdownRemark
  const jobDetails = job.frontmatter
  return (
    <Layout>
      <SEO title={jobDetails.title} />
      <div className={styles.container}>
        <div className={styles.introDetails}>
          <div className={styles.introDetails1} />
          <div className={styles.introDetails2} />
          <div className={styles.introDetails3} />
        </div>
        <div className={styles.heading}>
          <h2 className={styles.title}>{jobDetails.subTitle}</h2>
          <h1 className={styles.subTitle}>{jobDetails.title}</h1>
        </div>

        <div
          className={styles.mainBody}
          dangerouslySetInnerHTML={{ __html: job.html }}
        />
        <a className={styles.button} href="mailto:studio@thisisundefined.com">
          APPLY NOW
        </a>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        subTitle
      }
    }
  }
`
